import type {AppRouterInstance} from "next/dist/shared/lib/app-router-context.shared-runtime";

export  function navigateBackwards(router:AppRouterInstance) {
    const lastMeaningfulPage = window.sessionStorage.getItem("etp-last-meaningful-page");
    if (lastMeaningfulPage) {
        if (lastMeaningfulPage !== window.location.pathname + window.location.search) {
            router.push(lastMeaningfulPage);
            window.sessionStorage.removeItem("etp-last-meaningful-page");
        } else {
            window.sessionStorage.removeItem("etp-last-meaningful-page");
            router.back();
        }
    } else {
        router.back();
    }
}

export function setLastMeaningfulPage() {
    window.sessionStorage.setItem("etp-last-meaningful-page", window.location.pathname+window.location.search);
}
