"use client";
import {createClientComponentClient, type User} from "@supabase/auth-helpers-nextjs";
import type {Database} from "@/app/database.types";
import React, {type CSSProperties, useEffect, useRef, useState, useTransition} from "react";
import {fakeUser} from "@/app/fake-users";
import {AvatarImage} from "@/components/tailwind/app/profile/avatar-image";
import "./timeline.scss";
import type {TimelinePage} from "@/types/page";
import dynamic from 'next/dynamic'
import {useRouter} from "next/navigation";
import {isPortrait, MOBILE_MAX_WIDTH} from "@/components/tailwind/app/device";
import {EditThisButton} from "@/components/tailwind/app/editor/edit-this-button";


const TimelineDisplay = dynamic(() => import('@/components/tailwind/app/timeline/timeline-display'), {ssr: false})

export type TimelineCriteria = "global" | "mine" | "user";
export const TIMELINE_PAGE_SIZE = {width: 340, height: 680}


async function messagesForCriteria(user: User, criteria: TimelineCriteria): Promise<TimelinePage[]> {
    if (criteria === "global") {
        const supabase = createClientComponentClient<Database>();
        const {data: localMessages} = await supabase
            .from("view_distinct_pushes")
            .select("*")
            .order("created_at", {ascending: false})
            .limit(40)
        ;
        return localMessages as TimelinePage[];
    }
    if (criteria === "mine") {
        const supabase = createClientComponentClient<Database>();
        const {data: localMessages} = await supabase
            .from("view_pages")
            .select("*")
            .eq("user_id", user.id)
            .order("created_at", {ascending: false})
            .limit(40)
        ;
        return localMessages as TimelinePage[];

    }
}

function subscribeForCriteria(user: User, criteria: TimelineCriteria, setMessages: (value: (((prevState: any[]) => any[]) | any[])) => void) {
    const supabase = createClientComponentClient<Database>();
    return supabase
        .channel("custom-insert-channel")
        .on(
            "postgres_changes",
            {event: "INSERT", schema: "public", table: "pushes"},
            (payload) => {
                if (payload) {
                    setMessages((prev) => [payload.new, ...prev]);
                }
            }
        )
        .subscribe();
}

function sizeClassesForPage(page: TimelinePage, isMobileWidth: boolean) {
    if (page.aspect === "landscape") {
        if (isMobileWidth) {
            if (isPortrait()) {
                return {
                    minHeight: "calc(100vw - 24px - 2rem)",
                    maxHeight: "calc(100vw - 24px - 2rem)",
                    minWidth: "calc(100dvh - 42px - 2rem)",
                    maxWidth: "calc(100dvh - 42px - 2rem)"
                };
            } else {
                return {
                    minWidth: "calc(100vw - 2rem)",
                    minHeight: "calc(100dvh - 66px - 3rem)",
                    maxHeight: "calc(100dvh - 66px - 3rem)"
                };
            }
        } else {
            return {
                minWidth: TIMELINE_PAGE_SIZE.height + "px",
                minHeight: TIMELINE_PAGE_SIZE.width + "px",
                maxHeight: TIMELINE_PAGE_SIZE.width + "px",
                maxWidth: TIMELINE_PAGE_SIZE.height + "px"
            };
        }
    } else if (page.aspect === "portrait") {
        if (isMobileWidth) {
            return {
                minWidth: "calc(100vw - 2rem)",
                minHeight: "calc(100dvh - 66px - 3rem)",
                maxHeight: "calc(100dvh - 66px - 3rem)"
            };
        } else {
            return {
                minWidth: TIMELINE_PAGE_SIZE.width + "px",
                minHeight: TIMELINE_PAGE_SIZE.height + "px",
                maxHeight: TIMELINE_PAGE_SIZE.height + "px",
                maxWidth: TIMELINE_PAGE_SIZE.width + "px"
            };
        }
    } else {
        if (isMobileWidth) {
            return {
                minWidth: "calc(100vw - 24px - 2rem)",
                maxWidth: "calc(100vw - 24px -2rem)",
                minHeight: "calc(100vw - 24px -2rem)",
                maxHeight: "calc(100vw - 2rem)"
            };

        } else {
            return {
                minWidth: TIMELINE_PAGE_SIZE.width + "px",
                minHeight: TIMELINE_PAGE_SIZE.width + "px",
                maxHeight: TIMELINE_PAGE_SIZE.width + "px",
                maxWidth: TIMELINE_PAGE_SIZE.width + "px"
            };

        }
    }
}

function itemClassesForPage(page: TimelinePage, isMobileWidth:
    boolean) {
    if (page.aspect === "landscape") {
        return isMobileWidth ? {
            minHeight: "calc(100vw - 2rem)",
            maxHeight: "calc(100vw - 2rem)",
            minWidth: "calc(100dvh - 66px - 2rem)",
            maxWidth: "calc(100dvh - 66px - 2rem)"
        } : {
            minWidth: TIMELINE_PAGE_SIZE.height + "px",
            minHeight: TIMELINE_PAGE_SIZE.width + "px",
            maxHeight: TIMELINE_PAGE_SIZE.width + "px",
            maxWidth: TIMELINE_PAGE_SIZE.height + "px"
        };
    } else {
        if (page.aspect === "portrait") {
            return isMobileWidth ? {
                minWidth: "calc(100vw - 2rem)",
                minHeight: "calc(100dvh - 66px - 3rem)",
                maxHeight: "calc(100dvh - 66px - 3rem)"
            } : {
                minWidth: TIMELINE_PAGE_SIZE.width + "px",
                minHeight: TIMELINE_PAGE_SIZE.height + "px",
                maxHeight: TIMELINE_PAGE_SIZE.height + "px",
                maxWidth: TIMELINE_PAGE_SIZE.width + "px"
            };
        } else {
            return isMobileWidth ? {
                minWidth: "calc(100vw - 2rem)",
                maxWidth: "calc(100vw - 2rem)",
                minHeight: "calc(100vw - 2rem)",
                maxHeight: "calc(100vw - 2rem)"
            } : {
                minWidth: TIMELINE_PAGE_SIZE.width + "px",
                minHeight: TIMELINE_PAGE_SIZE.width + "px",
                maxHeight: TIMELINE_PAGE_SIZE.width + "px",
                maxWidth: TIMELINE_PAGE_SIZE.width + "px"
            };
        }
    }
}

export default function Timeline({criteria, mobileOnly = false, desktopOnly = false}: {
    criteria: TimelineCriteria,
    mobileOnly?: boolean,
    desktopOnly?: boolean
}) {
    const supabase = createClientComponentClient<Database>();
    const [messages, setMessages] = useState([]);
    const [user, setUser] = useState(null);
    const router = useRouter();
    const [isPending, startTransition] = useTransition();
    const [columnCount, setColumnCount] = useState(2);
    const [isMobileWidth, setIsMobileWidth] = useState(null);

    useEffect(() => {
        setIsMobileWidth(window.innerWidth <= MOBILE_MAX_WIDTH);
        setColumnCount(Math.floor(window.innerWidth / (TIMELINE_PAGE_SIZE.width)));

        function handleResize() {
            setIsMobileWidth(window.innerWidth <= MOBILE_MAX_WIDTH);
            setColumnCount(Math.floor(window.innerWidth / (TIMELINE_PAGE_SIZE.width)));
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const chatContainerRef = useRef(null); // Create a ref for the chat container

    const messagesRef = useRef<TimelinePage[]>([]);
    messagesRef.current = messages;

    useEffect(() => {
        window.localStorage.setItem("returnPath", window.location.href);

        (async () => {
            const data = await supabase.auth.getUser();
            let localUser = data.data.user;
            if (localUser === null) {
                console.log("Signing in anonymously");
                // await supabase.auth.signInAnonymously();
                localUser = (await supabase.auth.getUser()).data.user;
                setUser(localUser);
            } else {
                console.log("User", localUser);
                setUser(localUser);
            }

            console.log("Fetching entries");
            const newMessages = await messagesForCriteria(localUser, criteria);
            newMessages.sort((a, b) => a.pinned ? -1 : (b.pinned ? 1 : 0))
            setMessages(newMessages);


            const messageSubscription = subscribeForCriteria(localUser, criteria, setMessages);

            return () => {
                supabase.removeChannel(messageSubscription);
            };

        })();
    }, []);

    const deleteMessage = async (messageId) => {
        const {error} = await supabase
            .from("pushes")
            .delete()
            .eq("edit_id", messageId);
        console.debug("Deleted message:", messageId, error)
        setMessages(prev => (prev.filter((message) => message.id !== messageId)));
        if (error) {
            console.error("Error deleting message:", error);
        }
    };

    const messageMapper = async (page: TimelinePage) => {
        try {
            const fakeUserObject = fakeUser(page.title || page.description);
            const name = (page.username !== "fake") ? (page.full_name || page.username || "anon") : fakeUserObject.full_name;
            const avatarUrl = page.username !== "fake" ? page.avatar_url : fakeUserObject.avatar_url;
            let sizeClasses: CSSProperties = {};
            sizeClasses = sizeClassesForPage(page, isMobileWidth);
            let itemClasses: CSSProperties;
            itemClasses = itemClassesForPage(page, isMobileWidth);
            // Create a new IntersectionObserver object

            const rotation = page.aspect === "landscape" && isMobileWidth && isPortrait ? "etp-timeline-item-iframe-rotated" : "";
            const clickAction = () => {
                const metro = document.getElementById("etp-metro-main");
                if (metro) {

                    const originalVersion = document.getElementById(`etp-timeline-iframe-${page.page_id}-${page.edit_id}`);
                    const clonedVersion = originalVersion.cloneNode(true) as HTMLIFrameElement;
                    const boundingClientRect = originalVersion.getBoundingClientRect();
                    console.log("Bounding client rect", boundingClientRect);
                    const backdrop = document.createElement("div");
                    backdrop.style.width = "100vw";
                    backdrop.style.height = "100dvh";
                    backdrop.style.position = "fixed";
                    backdrop.style.top = "0";
                    backdrop.style.left = "0";
                    backdrop.style.opacity = "0.2";
                    backdrop.style.transition = "opacity 0.5s";
                    backdrop.style.backgroundColor = "rgb(0,0,0)";
                    backdrop.addEventListener("mousedown", () => {
                        metro.className = metro.className.replace(" active", "");
                        setTimeout(() => {
                            clonedVersion.style.left = boundingClientRect.left + "px";
                            clonedVersion.style.top = boundingClientRect.top + "px";
                            clonedVersion.style.width = boundingClientRect.width + "px";
                            clonedVersion.style.height = boundingClientRect.height + "px";
                            clonedVersion.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
                        }, 200);
                        originalVersion.style.opacity = "1";
                        backdrop.style.opacity = "0";
                        setTimeout(() => {
                            originalVersion.style.display = "block";
                            clonedVersion.remove();
                            backdrop.remove();
                        }, 1000);
                    });
                    clonedVersion.className += " etp-timeline-item-cloned";
                    clonedVersion.src = `/ro/${page.page_id}/${page.edit_id}/view`;
                    clonedVersion.scrolling = "yes";
                    clonedVersion.style.left = boundingClientRect.left + "px";
                    clonedVersion.style.top = boundingClientRect.top + "px";
                    clonedVersion.style.width = boundingClientRect.width + "px";
                    clonedVersion.style.height = boundingClientRect.height + "px";
                    clonedVersion.style.zIndex = "1000";
                    clonedVersion.style.position = "fixed";
                    clonedVersion.style.opacity = "0";
                    clonedVersion.style.backgroundColor = "white";
                    clonedVersion.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
                    clonedVersion.style.transition = "top 0.5s, left 0.5s, max-width 0.5s, max-height 0.5s, width 0.5s, height 0.5s, box-shadow 2s"

                    document.body.append(clonedVersion);

                    setTimeout(() => {
                        metro.className += " active";
                        clonedVersion.style.opacity = "1";
                        document.body.append(backdrop);
                        originalVersion.style.display = "none";
                        setTimeout(() => {
                            clonedVersion.style.maxWidth = "90vw";
                            clonedVersion.style.maxHeight = "calc(100dvh - 42px - 4rem)";
                            clonedVersion.style.width = "90vw";
                            clonedVersion.style.height = "calc(100dvh - 42px - 4rem)";
                            clonedVersion.style.top = "42px";
                            clonedVersion.style.left = "5vw";
                            clonedVersion.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.2)";
                        }, 200);

                    }, 200);

                } else {
                    router.push(`/ro/${page.page_id}/${page.edit_id}/view`);
                }
            };
            // router.prefetch(`/ro/${page.page_id}/${page.edit_id}/timeline`);
            // if (page.image) {
            //     router.prefetch(page.image);
            // }
            return (
                <div data-user={page.user_id === user?.id}
                     data-aspect={page.aspect} key={page.edit_id}
                     className={"etp-timeline-item-surround relative snap-always snap-center mt-2 md:mt-0 mx-4 md:mx-0 "}>

                    <div
                        id={`etp-timeline-${page.page_id}-${page.edit_id}`}
                        className={"etp-timeline-item md:mx-0 " + rotation + " "}
                        style={itemClasses}
                    >

                        <div className="etp-timeline-item-infobar opacity-1 md:opacity-0 bg-foreground text-background absolute h-4 left-0 right-0 flex flex-row -z-1">
                            <div className="flex flex-shrink mt-1 ml-2">
                                <AvatarImage
                                    full_name={page.full_name}
                                    url={avatarUrl}
                                    size={14}
                                />
                            </div>
                            <div className="flex flex-grow-1 flex-col ml-2 mt-1">
                                <div className="text-xs text-gray-400 ">{name} &nbsp;

                                        {new Date(page.created_at).toLocaleString()}

                                        {page.user_id === user?.id && (
                                            <span className="absolute right-1 top-1">
                            {/*<a*/}
                                                {/*    className="text-red-700 opacity-70 hover:text-red-400"*/}
                                                {/*    onClick={() => deleteMessage(page.edit_id)}*/}
                                                {/*>*/}
                                                {/*    <X size={12}/>*/}
                                                {/*</a>*/}
                            </span>
                                        )}
                                </div>

                            </div>
                        </div>
                        <iframe scrolling={"no"}
                                id={`etp-timeline-iframe-${page.page_id}-${page.edit_id}`}
                            // onMouseEnter={(e) => (e.target as any).scrolling = 'yes'}
                            // onMouseLeave={(e) => (e.target as any).scrolling = 'no'}
                            // onTouchStart={(e) => (e.target as any).scrolling = 'yes'}
                                className={`etp-timeline-iframe border-0 flex m-0 `}
                                style={sizeClasses}
                                data-src={`/ro/${page.page_id}/${page.edit_id}/timeline`}
                        >

                        </iframe>
                        <div className="etp-timeline-item-cover absolute top-0 left-0 mt-0" style={{
                            ...sizeClasses,
                        }}
                             data-image={page.image}
                             id={`etp-timeline-iframe-cover-${page.page_id}-${page.edit_id}`}
                             onMouseEnter={() =>
                                 router.prefetch(`/ro/${page.page_id}/${page.edit_id}/view`)
                             }

                             onMouseDown={clickAction}


                        ></div>

                        <EditThisButton pageId={page.page_id} editId={page.edit_id} style="timeline"/>
                        {/*{ !isMobileWidth && <PageTabs push_id={page.push_id} page_id={page.page_id} edit_id={page.edit_id}/> }*/}
                    </div>
                </div>
            )
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    useEffect(() => {
        let coverObserver = new IntersectionObserver(function (entries, observer) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    const coverImages = entry.target.parentElement.querySelectorAll(".etp-timeline-item-cover-image");
                    for (const coverImage of coverImages) {
                        console.log("Element is fully visible in screen");
                        setTimeout(() => {
                            (coverImage as HTMLImageElement).style.opacity = "0";
                        }, 1500);
                        setTimeout(() => {
                            console.log("Element is fully visible in screen");
                            coverImage.remove();
                        }, 1000);
                    }
                } else {
                    if (!entry.target.parentElement.querySelector(".etp-timeline-item-cover-image")) {
                        console.log("Element is not fully visible in screen");
                        const coverImage = document.createElement("img");
                        coverImage.style.position = "absolute";
                        coverImage.style.top = "0";
                        coverImage.style.left = "0";
                        coverImage.style.width = "100%";
                        coverImage.style.height = "100%";
                        coverImage.className = "etp-timeline-item-cover-image";
                        coverImage.src = entry.target.getAttribute("data-image");
                        coverImage.style.opacity = "1";
                        coverImage.onmousedown=(entry.target as HTMLElement).onmousedown;
                        entry.target.parentElement.append(coverImage);
                    }
                }
            }
        }, {threshold: 0.01, rootMargin: "40%"});
        setTimeout(() => {
            console.log("Setting up intersection API")
            const elementsByClassName = document.querySelectorAll(".etp-timeline-item-cover");
            console.log("Elements by class name", elementsByClassName)
            for (let element of elementsByClassName) {
                console.log("Observing element", element);
                coverObserver.observe(element);
            }
        }, 4000);

        return () => {
            coverObserver.disconnect();
        };
    }, []);


    useEffect(() => {
        let observer = new IntersectionObserver(function (entries, observer) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    console.log("Element is fully visible in screen");
                    (entry.target as HTMLIFrameElement).src = entry.target.getAttribute("data-src");
                } else {
                    console.log("Element is not fully visible in screen");
                    (entry.target as HTMLIFrameElement).src = "";

                }
            }
        }, {threshold: 0.01, rootMargin: "40%"});
        setTimeout(() => {
            console.log("Setting up intersection API")
            const elementsByClassName = document.querySelectorAll(".etp-timeline-iframe");
            console.log("Elements by class name", elementsByClassName)
            for (let element of elementsByClassName) {
                console.log("Observing element", element);
                observer.observe(element);
            }
        }, 4000);

        return () => {
            observer.disconnect();
        };
    }, []);

    if (isMobileWidth === null) {
        return <></>;
    }
    return (
        <TimelineDisplay containerRef={chatContainerRef} isMobile={isMobileWidth}>
            {messages.map((page) => messageMapper(page))}
        </TimelineDisplay>

    );
};
