import Bowser from "bowser";

export const MOBILE_STATE_UNKNOWN = typeof window === 'undefined';
export const IS_MOBILE = !MOBILE_STATE_UNKNOWN && Bowser.getParser(window.navigator.userAgent).getPlatformType(true) === 'mobile';
export const IS_TABLET = !MOBILE_STATE_UNKNOWN && Bowser.getParser(window.navigator.userAgent).getPlatformType(true) === 'tablet';
export const IS_DESKTOP = !MOBILE_STATE_UNKNOWN && Bowser.getParser(window.navigator.userAgent).getPlatformType(true) === 'desktop';
export const IS_MOBILE_OR_TABLET = IS_TABLET || IS_MOBILE;
export const HAS_TOUCH = !MOBILE_STATE_UNKNOWN && window.matchMedia("(pointer: coarse)").matches
export const MOBILE_MAX_WIDTH = 767;
export const PHONE_MAX_WIDTH = 450;
export const HAS_MOBILE_WIDTH = !MOBILE_STATE_UNKNOWN && window.innerWidth < MOBILE_MAX_WIDTH;

export const isPortrait = ()=>!MOBILE_STATE_UNKNOWN && window.matchMedia("(orientation: portrait)").matches;
export const IS_LANDSCAPE = ()=>!MOBILE_STATE_UNKNOWN && window.matchMedia("(orientation: landscape)").matches;
