import {CircleUserRound} from "lucide-react";
import React, {useEffect, useState} from "react";
import {createClientComponentClient} from "@supabase/auth-helpers-nextjs";
import type {Database} from "@/app/database.types";

export function AvatarImage({url, size, full_name=null}) {
    const [avatarUrl, setAvatarUrl] = useState(null);
    const supabase = createClientComponentClient<Database>()


    useEffect(() => {
        async function downloadImage(path: string) {
            if (path.startsWith("http")) {
                console.log(url);
                setAvatarUrl(path);
            } else {
                try {
                    const {data, error} = await supabase.storage.from('avatars').download(path)
                    if (error) {
                        throw error
                    }

                    console.log("Data: ", data);
                    const blobUrl = URL.createObjectURL(data)
                    console.log("Setting URL: ", blobUrl);
                    setAvatarUrl(blobUrl)
                } catch (error) {
                    console.log('Error downloading image: ', error)
                }
            }
        }

        if (url) {
            downloadImage(url)
        } else {
            if(full_name) {
                setAvatarUrl("https://ui-avatars.com/api/?name="+encodeURIComponent(full_name));
            }
        }

    }, [url])

    return <>
        {avatarUrl ? (
            <img
                width={size}
                height={size}
                src={avatarUrl}
                alt="Avatar"
                className="avatar image rounded-full object-cover"
                style={{height: size, width: size, borderRadius: "50%", border: "1px solid #fff"}}
            />
        ) : (
            <CircleUserRound size={size}/>
        )}
    </>;
}
