"use client";
import {Button} from "@/components/tailwind/ui/button";
import {EditIcon} from "lucide-react";
import {useRouter} from "next/navigation";
import React, {useEffect} from "react";
import "./edit-this-button.scss";

export function EditThisButton(props: {pageId: string, editId: string, style: "timeline" | "ro"}) {
    const router = useRouter();
    const [visible, setVisible] = React.useState(false);
    useEffect(() => {
        setVisible(window.self === window.top && window.visualViewport.width > 768)
    }, []);
    return visible && (<Button
        className="etp-edit-btn right-6 bottom-12 sm:bottom-16 z-10 rounded-full shadow-lg bg-white hover:bg-gray-100 text-gray-900 px-4 py-4 text-sm h-12"
        style={ {
            position: props.style === "timeline" ? "absolute" : "fixed"
        }}
        onClick={() => {
            window.location.href=`/p/${props.pageId}?i=${props.editId}`;
        }}
    >

        <span className="etp-edit-btn-text">Edit This Page</span><EditIcon className="etp-edit-btn-icon" size={28}></EditIcon>
    </Button>);
}
