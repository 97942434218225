"use client";


const fakeUsers = [
    { full_name: "Marlee", username: "mmarlee", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=2"},
    { full_name: "Salvador Conley", username: "ssconlet", gender: "Male", avatar_url: "https://i.pravatar.cc/150?img=3" },
    { full_name: "Salem Haley", username: "witchy_salem", gender: "Female", avatar_url: "https://i.pravatar.cc/3090" },
    { full_name: "Leif Walter", username: "viking_leif", gender: "Male", avatar_url: "https://i.pravatar.cc/150?img=11" },
    { full_name: "Penny Fox", username: "tuppenny", gender: "Female",avatar_url: "https://i.pravatar.cc/150?img=9"},
    { full_name: "Antonio Pham", username: "bigfarmer", gender: "Male",avatar_url: "https://i.pravatar.cc/150?img=12" },
    { full_name: "Rae Hensley", username: "rae.hensley", gender: "Female", avatar_url:"https://i.pravatar.cc/150?img=10"},
    { full_name: "Layne Doyle", username: "lane_explorer", gender: "Female",avatar_url: "https://i.pravatar.cc/150?img=9" },
    { full_name: "Aniyah Jarvis", username: "ani_the_artist", gender: "Female" },
    { full_name: "Marlon Tate", username: "mtate12", gender: "Male", avatar_url: "https://i.pravatar.cc/150?img=13" },
    { full_name: "Skye Cannon", username: "byeskye", gender: "Female" },
    { full_name: "Zaria Howell", username: "zaria", gender: "Female", avatar_url: "https://api.multiavatar.com/kathrin.svg" },
    { full_name: "Bradley Leonard", username: "brad_the_bard", gender: "Male", avatar_url: "https://robohash.org/stefan-one" },
    { full_name: "Tobias Leach", username: "tleach", gender: "Male", avatar_url: "https://api.dicebear.com/7.x/pixel-art/svg" },
    { full_name: "Martha Burton", username: "mjb", gender: "Female", avatar_url: "https://api.multiavatar.com/zoe.svg" },
    { full_name: "Xander Bradshaw", username: "xanderbanter", gender: "Male", avatar_url: "https://robohash.org/robert-one" },
    { full_name: "Berkley Norris", username: "berkn", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=16" },
    { full_name: "Cairo Finley", username: "cairo", gender: "Male", avatar_url: "https://i.pravatar.cc/150?img=15" },
    { full_name: "Elian George", username: "eliange", gender: "Male" },
    { full_name: "Adelyn Villalobos", username: "villafan", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=19" },
    { full_name: "Reuben", username: "knightwatch", gender: "Male" },
    { full_name: "Alicia Klein", username: "ally99", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=22" },
    { full_name: "Marco Whitehead", username: "marwhite", gender: "Male" },
    { full_name: "Angelo Bowers", username: "agb1996", gender: "Male" },
    { full_name: "Reid Randolph", username: "fantastic3", gender: "Male" },
    { full_name: "Kailey McGuire", username: "kailey_the_keeper", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=23" },
    { full_name: "Memphis Lim", username: "music.in.1", gender: "Male" },
    { full_name: "Kara Peters", username: "karakara", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=29" },
    { full_name: "Patrick Truong", username: "padawan09", gender: "Male" },
    { full_name: "Bridger Clarke", username: "bobthebridger", gender: "Male" },
    { full_name: "Kaitlyn Sawyer", username: "kait_the_killer", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=28" },
    { full_name: "Jefferson Oliver", username: "airplanet", gender: "Male" },
    { full_name: "Camille C. Tang", username: "cctang", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=31"},
    { full_name: "Finn Delgado", username: "finn_the_finder", gender: "Male" },
    { full_name: "Malakai Shaffer", username: "malmage", gender: "Male", avatar_url: "https://i.pravatar.cc/150?img=33" },
    { full_name: "Alanna Hardy", username: "llamalana", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=32" },
    { full_name: "Jayceon Lam", username: "jay_the_jester", gender: "Male" },
    { full_name: "Karina Sanchez", username: "kari_the_knight", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=34" },
    { full_name: "Joseph Henson", username: "joey_the_joker", gender: "Male" },
    { full_name: "Elijah Lugo", username: "elilugo", gender: "Male" },
    { full_name: "Kaylie Pena", username: "kayp03", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=35" },
    { full_name: "Marcus Xiong", username: "marcamancer", gender: "Male" },
    { full_name: "Amayah Pitts", username: "notthepitts", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=30" },
    { full_name: "Trey Kim", username: "trek.trey", gender: "Male" },
    { full_name: "Raiden Mata", username: "raiden_the_radical", gender: "Male" },
    { full_name: "Lillie Rowland", username: "lillie.luminous", gender: "Female",  avatar_url: "https://robohash.org/lillie-one"},
    { full_name: "Eliezer Alfaro", username: "geezer.123", gender: "Male" },
    { full_name: "Marcelo Hart", username: "amarc", gender: "Male" },
    { full_name: "Gemma Keller", username: "gem_the_genie", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=36" },
    { full_name: "Nico Cummings", username: "nicoiscummings", gender: "Male" },
    { full_name: "Ernesto Rivers", username: "ernie_the_explorer", gender: "Male"},
    { full_name: "Kiana Anderson", username: "kiki_the_kinetic", gender: "Female", avatar_url: "https://i.pravatar.cc/150?img=37" },
    { full_name: "Jacob Jacobson", username: "jake_the_jester", gender: "Male" },
    { full_name: "Clare Solis", username: "clare_the_clear", gender: "Female" },
    { full_name: "Bentley Park", username: "bent_the_builder", gender: "Male" },
    { full_name: "Lia Sierra", username: "lia_the_lionheart", gender: "Female" },
    { full_name: "Dayton Brady", username: "day_the_daring", gender: "Male" },
    { full_name: "Ryan Atkins", username: "ryan_the_rascal", gender: "Male" },
    { full_name: "Cason Stanley", username: "case_the_crafter", gender: "Male" },
    { full_name: "Julien Chung", username: "jules_the_jester", gender: "Male" },
    { full_name: "Oakley W", username: "oak_the_oak", gender: "Male" },
    { full_name: "Atticus", username: "atti_the_attacker", gender: "Male" },
    { full_name: "Damari Cunningham", username: "dam_the_daring", gender: "Male" },
    { full_name: "Marley Lawson", username: "marley_the_marvel", gender: "Female" },
    { full_name: "Lane Arias", username: "lane_the_lancer", gender: "Female" },
    { full_name: "Aleah Schneider", username: "leah_the_legend", gender: "Female" },
    { full_name: "Raymond Ventura", username: "ray_the_rascal", gender: "Male" },
    { full_name: "Gunnar Contreras", username: "gunnar_the_guardian", gender: "Male" },
    { full_name: "Diego Liu", username: "dragon912", gender: "Male" },
    { full_name: "Romeo Lewis", username: "romeo.romeo", gender: "Male" },
    { full_name: "Ellie McIntosh", username: "ellie", gender: "Female" },
    { full_name: "Kristian Molina", username: "krismo", gender: "Male" },
    { full_name: "Raphael R. Russo", username: "rrr1998", gender: "Male" },
    { full_name: "Otto Trevino", username: "ottlaw", gender: "Male" },
    { full_name: "Aldo Munoz", username: "aldo_the_alchemist", gender: "Male" },
    { full_name: "Kehlani Lu", username: "kehla_the_keeper", gender: "Female" },
    { full_name: "Duncan Aguilar", username: "dunc_the_dragon", gender: "Male" },
    { full_name: "Josie Reyes", username: "josie_the_jester", gender: "Female" },
    { full_name: "Eli", username: "eli_the_enigma", gender: "Male" },
    { full_name: "Hailey Russell", username: "hailz_the_huntress", gender: "Female" },
    { full_name: "Weston Wilson", username: "wes_the_warrior", gender: "Male" },
    { full_name: "Joziah Poole", username: "ziah_the_zealot", gender: "Male" },
    { full_name: "Adriel Yang", username: "thearcher", gender: "Male" },
    { full_name: "Douglas Church", username: "druid.dog", gender: "Male" },
    { full_name: "Ayleen Berg", username: "aylee_the_amazon", gender: "Female" },
    { full_name: "Cayson Daniel", username: "cay89", gender: "Male" },
    { full_name: "Joy Galvan", username: "gal.joy", gender: "Female" },
    { full_name: "Josephine Ryan", username: "jojest", gender: "Female" },
    { full_name: "Timothy Lowe", username: "tim_the_trickster", gender: "Male" }]


export function fakeUser(title:string) {
    // console.log("Fake User for title", title);
    const fakeId = title ? title.length : 0;
    const fakeUserCount = fakeId % fakeUsers.length;
    const fakeUser = fakeUsers[fakeUserCount];
    const genderList = fakeUsers.filter(i => fakeUser.gender === i.gender)
    const fakeFullname =  fakeUser.full_name;
    const fakeUsername =  fakeUser.username;
    const fakeAvatarUrl =  fakeUser.avatar_url ? fakeUser.avatar_url :( "https://xsgames.co/randomusers/assets/avatars/" + fakeUser.gender.toLowerCase() + "/" + (fakeId % genderList.length) + ".jpg");
    const user = {full_name:fakeFullname, avatar_url:fakeAvatarUrl, username:fakeUsername};
    // console.log("Fake User ",user);
    return user;
}
