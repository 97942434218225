"use client";
import React from "react";
import Timeline from "@/components/tailwind/app/timeline/timeline";
import {setLastMeaningfulPage} from "@/components/tailwind/app/navigation";

export default function Page({params}) {
    if(typeof window !== 'undefined') {
        window.localStorage.setItem("etp-last-timeline", "/p/timeline/" + params.criteria);
        setLastMeaningfulPage();
    }
    return (
        <Timeline criteria={params.criteria}></Timeline>
    )
}
